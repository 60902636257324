// import store from '@/store'

// const { pedidos } = store.state.pedidos
// const pendientes = pedidos.filter(p => p.status === 0)

export default [
  {
    header: 'Expedientes',
  },
  {
    title: 'List',
    icon: 'ListIcon',
    route: { name: 'expedientes-list' },
  },
  {
    title: 'CCAA',
    icon: 'PrinterIcon',
    children: [
      {
        title: 'Madrid',
        route: { name: 'expedientes-list-ccaa', params: { ccaa: 'MD' } },
      },
    ],
  },
]
